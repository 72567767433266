import { ActionTree } from 'vuex';
import RootStateType from './RootStateType.type';
import { RootMutationsEnum } from './RootStateType.type';

const actions: ActionTree<RootStateType, unknown> = {
  setAppLoading({ commit }, payload) {
    commit(RootMutationsEnum.IsAppLoading, payload);
  },
};

export default actions;
