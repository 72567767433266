import AuthenticationModule from './modules/authentication';
import LoadingModule from './modules/loading';
import campaignsModule from './modules/campaigns';
import dashboardModule from '@/store/modules/dashboard';
import menuModule from '@/store/modules/menu';
const modules = {
  authentication: AuthenticationModule,
  loading: LoadingModule,
  campaigns: campaignsModule,
  dashboard: dashboardModule,
  menu: menuModule,
};

export default modules;
