<template>
  <div
    class="bg-white md:bg-body-gradient rtl font-ReadexPro"
    :dir="$i18n.locale === I18nLocalesEnum.EN ? DirectionsEnum.LTR : DirectionsEnum.RTL"
  >
    <router-view />
    <SupportDialog />
    <LoadingDialog />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import LoadingDialog from '@/components/LoadingDialog.vue';
import SupportDialog from '@/components/SupportDialog.vue';
import { DirectionsEnum, I18nLocalesEnum } from '@/types/i18n/i18n.enum';
import { isMerchantAuthenticatedLocally, updateGlobalStorageWithLocal } from '@/helpers/auth/useAuthStatus';

export default defineComponent({
  components: {
    SupportDialog,
    LoadingDialog,
  },
  watch: {
    '$i18n.locale': function (newVal) {
      document
        ?.querySelector('html')
        ?.setAttribute('dir', newVal === I18nLocalesEnum.AR ? DirectionsEnum.RTL : DirectionsEnum.LTR);
    },
  },
  mounted() {
    document
      ?.querySelector('html')
      ?.setAttribute('dir', this.$i18n.locale === I18nLocalesEnum.AR ? DirectionsEnum.RTL : DirectionsEnum.LTR);
  },
  setup() {
    if (isMerchantAuthenticatedLocally()) updateGlobalStorageWithLocal();

    return {
      I18nLocalesEnum,
      DirectionsEnum,
    };
  },
});
</script>
<style lang="scss" src="./app.scss" />
