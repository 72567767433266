import httpClient from '@/api/httpClient';

export const getFunnelMetrics = (filter: any) => {
  const ENDPOINT_URL = '/merchant/insights/funnel-metrics';
  return httpClient
    .get(ENDPOINT_URL, {
      params: filter,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getCampaignChart = (filter: any) => {
  const ENDPOINT_URL = '/merchant/insights';
  return httpClient
    .get(ENDPOINT_URL, {
      params: filter,
    })
    .then((response) => response.data)
    .catch((error) => error);
};

export const getCampaignByView = (filter: any) => {
  const ENDPOINT_URL = '/merchant/insights/campaign-view-metrics';
  return httpClient
    .get(ENDPOINT_URL, {
      params: filter,
    })
    .then((response) => response.data)
    .catch((error) => error);
};

export const getCampaignByConversion = (filter: any) => {
  const ENDPOINT_URL = '/merchant/insights/campaign-conversion-metrics';
  return httpClient
    .get(ENDPOINT_URL, {
      params: filter,
    })
    .then((response) => response.data)
    .catch((error) => error);
};

export const getCampaignByGrossSales = (filter: any) => {
  const ENDPOINT_URL = '/merchant/insights/campaign-gross-sales-metrics';
  return httpClient
    .get(ENDPOINT_URL, {
      params: filter,
    })
    .then((response) => response.data)
    .catch((error) => error);
};
