import { createLogger, createStore } from 'vuex';
import modules from './modules';
import rootActions from './actions';
import rootMutations from './mutations';
import rootGetters from './getters';

const debug = process.env.NODE_ENV !== 'production';
export default createStore({
  strict: debug,
  state: {
    isAppLoading: false,
    supportDialog: false,
  },
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions,
  modules,
  plugins: debug ? [createLogger()] : [],
});
