import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { RoutesNamesEnum } from '@/router/routes-names.enum';
import { isMerchantAuthenticatedLocally, resetStorageAndGlobalStateAuth } from '@/helpers/auth/useAuthStatus';

export const useAuthentication = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  // log out
  if (to.name === RoutesNamesEnum.LogOut) {
    resetStorageAndGlobalStateAuth();
    return next({ name: RoutesNamesEnum.LogIn });
  }

  //from any route to route that requires authentication
  if (to.matched.some((route) => route.meta.requireAuth)) {
    if (isMerchantAuthenticatedLocally()) return next();
    else return next({ name: RoutesNamesEnum.LogIn });
  }

  //from any route to route that doesn not require authentication
  if (!to.matched.some((route) => route.meta.requireAuth)) {
    if (isMerchantAuthenticatedLocally()) {
     return next({ name: RoutesNamesEnum.Dashboard });
    } else return next();
  }
};
