<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-[999999]">
      <div class="fixed inset-0 bg-black/30" aria-hidden="true" @click="closeModal" />
      <TransitionChild
        as="template"
        enter="duration-100 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-100 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all w-[400px] h-[400px] flex flex-column justify-center align-center"
            >
              <div class="transition" :class="{ 'delay-20 -translate-y-10': finishLoading }">
                <LoadingLogo :loading="loading" :finishLoading="finishLoading" :fill="fill" />
              </div>
              <transition name="fade">
                <div
                  v-if="finishLoading"
                  class="bg-primary-gradient bg-clip-text text-transparent ltr:font-Inter rtl:font-ReadexPro text-[20px] font-700 text-center"
                >
                  {{ message }}
                </div>
              </transition>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue';
import LoadingLogo from '@/components/LoadingLogo.vue';
import { Ref } from 'vue';
import { useStore } from 'vuex';

const isOpen = ref(false);
const finishLoading = ref(false);
const fill: Ref<string | null> = ref(null);
const store = useStore();

const loading = computed(() => store.state.loading.loading);
const error = computed({
  get() {
    return store.state.loading.error;
  },
  set(value) {
    store.commit('loading/setError', value);
  },
});
const success = computed({
  get() {
    return store.state.loading.success;
  },
  set(value) {
    store.commit('loading/setSuccess', value);
  },
});
const message = computed(() => store.state.loading.message);
const closeModal = () => {
  if (loading.value && !finishLoading.value) return;
  isOpen.value = false;
};
watch(loading, (newValue) => {
  if (!newValue) {
    finishLoading.value = true;
    let msgTime = 800;
    if (error.value) {
      error.value = true;
      fill.value = 'red';
      // msgTime = 800;
    } else if (success.value) {
      success.value = true;
      fill.value = 'green';
      // msgTime = 800;
    }
    setTimeout(() => (fill.value = null), 300);
    setTimeout(() => {
      isOpen.value = false;
      store.commit('loading/finishLoading');
    }, msgTime);
  } else {
    isOpen.value = newValue;
    finishLoading.value = false;
  }
});
</script>
