import { CreateCampaignErrors, CreateCampaignInterface } from '@/types/Campaigns';

export interface CampaignsStateInterface {
  campaigns: any[];
  products: any[];
  createCampaign: CreateCampaignInterface;
  createCampaignErrors: CreateCampaignErrors;
}

export enum CampaignsStateEnum {
  GetAllCurrentCampaigns = 'GET_ALL_CURRENT_CAMPAIGNS',
  SetAllCampaigns = 'SET_ALL_CAMPAIGN',
  setCreateCampaign = 'SET_CREATE_CAMPAIGN',
  setCreateCampaignErrors = 'SET_CREATE_CAMPAIGN_ERRORS',
  clearCreateCampaign = 'CLEAR_CREATE_CAMPAIGN',
  setProducts = 'SET_PRODUCTS',
}
