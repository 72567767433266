/* eslint-disable*/
export interface AuthTokenInterface {
    access: string;
    type: string;
    expires_at: string;
}

export interface AuthStoreInterface {
    platform: string;
    store_id: number;
    mobile: number;
    email: string;
    store_url: string;
    title: string;
}

export enum AuthenticationResponse {
    MerchantToken = 'merchant-token',
    TokenType = 'token-type',
    TokenExpiresAt = 'expires-at',
    Platform = 'platform',
    StoreID = 'store-id',
    Mobile = 'mobile',
    Email = 'email',
    StoreURL = 'store-url',
    StoreTitle = 'store-title',
}


export interface AutoResponseInterface {
    token: AuthTokenInterface;
    store: AuthStoreInterface;
}
