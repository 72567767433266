import { mutations } from '@/store/modules/loading/mutations';
import { actions } from '@/store/modules/loading/actions';

export default {
  namespaced: true,
  mutations,
  actions,
  state: {
    loading: false,
    error: false,
    success: false,
    message: '',
  },
};
