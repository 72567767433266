import { AuthStoreInterface } from '@/types/auth/auth-interface';

interface AuthenticationStateInterface {
  id: number;
  storeId: number;
  name: string;
  email: string;
  xMerchantToken: string;
  tokenExpirationDate: string;
  isMerchantAuthenticated: boolean;
  tokenType: string;
  store: AuthStoreInterface;
}

export enum AuthenticationMutationsEnum {
  ResetMerchantInfo = 'RESET_MERCHANT_INFO',
  SetMerchantId = 'SET_MERCHANT_ID',
  SetStoretId = 'SET_STORE_ID',
  SetMerchantName = 'SET_MERCHANT_NAME',
  SetMerchantEmail = 'SET_MERCHANT_EMAIL',
  SetXMerchantToken = 'SET_X_MERCHANT_TOKEN',
  SetTokenExpirationDate = 'SET_TOKEN_EXPIRATION_DATE',
  SetMerchantAuthenticated = 'SET_MERCHANT_AUTHENTICATED',
  SetTokenType = 'SET_MERCHANT_TOKEN_TYPE',
  SetMerchantStore = 'SET_MERCHANT_STORE',
}

export default AuthenticationStateInterface;
