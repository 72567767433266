import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const getInitialCreateCampaignState = () => ({
  name: '',
  trigger_type: null,
  campaign_type: null,
  campaign_style: null,
  trigger_product: null,
  trigger_product_ids: [],
  campaign_action: null,
  action_product_ids: [],
  selected_products: [],
  main_selected_products: [],
  alternative_selected_products: [],
  is_product_coupon_enabled: false,
  alternative_product_setting: 0,
  alternative_product_ids: [],
  product_coupon: {
    title: '',
    coupon_condition: null,
    discount_type: '1',
    discount_value: '',
    max_amount: 0,
    is_free_shipping: false,
    is_free_cod: false,
    after_appear_expiration_date: 1,
    expiration_date_settings: 1,
    fixed_expiration_date: null,
  },
  card_details: {
    title: {
      ar: '',
      en: '',
    },
    description: {
      ar: '',
      en: '',
    },
  },
  campaign_options: {
    limited_quantity: 0,
    limited_quantity_value: 1,
    life_time: {
      start_at: null,
      end_at: null,
    },
  },
});
export default {
  namespaced: true,
  state: {
    campaigns: [],
    createCampaign: {
      ...getInitialCreateCampaignState(),
    },
    products: [],
    createCampaignErrors: {
      campaignName: false,
      couponTitle: { en: false, ar: false },
      windowTitle: { en: false, ar: false },
      windowDescription: { en: false, ar: false },
    },
  },
  actions,
  mutations,
  getters,
};
