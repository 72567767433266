import { ActionTree } from 'vuex';
import AuthenticationStateInterface from './authentication-state.interface';
import { AuthenticationMutationsEnum } from './authentication-state.interface';

export const actions: ActionTree<AuthenticationStateInterface, unknown> = {
  resetMerchantData({ commit }) {
    commit(AuthenticationMutationsEnum.ResetMerchantInfo);
  },
  setMerchantID({ commit }, id) {
    commit(AuthenticationMutationsEnum.SetMerchantId, id);
  },
  setStoreID({ commit }, storeId) {
    commit(AuthenticationMutationsEnum.SetStoretId, storeId);
  },
  setMerchantName({ commit }, merchantName) {
    commit(AuthenticationMutationsEnum.SetMerchantName, merchantName);
  },
  setMerchantEmail({ commit }, merchantEmail) {
    commit(AuthenticationMutationsEnum.SetMerchantEmail, merchantEmail);
  },
  setXMerchantToken({ commit }, xMerchantToken) {
    commit(AuthenticationMutationsEnum.SetXMerchantToken, xMerchantToken);
  },
  setTokenExpirationDate({ commit }, tokenExpirationDate) {
    commit(AuthenticationMutationsEnum.SetTokenExpirationDate, tokenExpirationDate);
  },
  setMerchantAuthenticated({ commit }, isMerchantAuthenticated) {
    commit(AuthenticationMutationsEnum.SetMerchantAuthenticated, isMerchantAuthenticated);
  },
  setMerchantTokenType({ commit }, tokenType) {
    commit(AuthenticationMutationsEnum.SetTokenType, tokenType);
  },
  setMerchantStore({ commit }, merchantStore) {
    commit(AuthenticationMutationsEnum.SetMerchantStore, merchantStore);
  },
};
