/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { serializeParams } from './paramsSerializer';
import useTokenStatus from './useTokenStatus';
import ErrorModel from './models/ErrorModel';
import { StoreModulesEnum } from '@/types/store/modules.enum';
import { getSelectedLocaleFromLocalStorage } from '@/i18n';
import store from '@/store';
import router from '@/router';
import { RoutesNamesEnum } from '@/router/routes-names.enum';
import { resetStorageAndGlobalStateAuth } from '@/helpers/auth/useAuthStatus';

const HttpClient: AxiosInstance = axios.create({
  // @ts-ignore
  baseURL: process.env.VUE_APP_ZIADAH_ENVIRONMENT_URL,
  headers: {
    Accept: 'application/json',
    // @ts-ignore
    'Access-Control-Allow-Origin': process.env.VUE_APP_ZIADAH_LOCAL_ENVIRONMENT_URL,
    'Content-Type': 'application/json',
    'Accept-Language': getSelectedLocaleFromLocalStorage(),
  },
  responseType: 'json',
  paramsSerializer: serializeParams,
});

HttpClient.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const { merchantToken, merchantTokenValid } = useTokenStatus();
    config.headers = {
      ...config.headers,
      'Accept-Language': getSelectedLocaleFromLocalStorage(),
      // @ts-ignore
      'api-key': process.env.VUE_APP_ZIADAH_API_KEY,
    };
    if (merchantToken && !!merchantTokenValid) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${merchantToken}`,
      };
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(new ErrorModel(error.response)),
);

HttpClient.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (error: AxiosError) => {
    // const { merchantTokenValid } = useTokenStatus();
    // if (!merchantTokenValid) {
    //   store.dispatch(`${StoreModulesEnum.Authentication}/resetMerchantData`);
    // }
    if (error.response?.status === 401 && !error.config.url?.includes('login')) {
      resetStorageAndGlobalStateAuth();
      router.replace('/login');
      return;
    }
    return Promise.reject(new ErrorModel(error.response));
  },
);

export default HttpClient;
