import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import i18n from './i18n';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import VueApexCharts from 'vue3-apexcharts';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueAwesomePaginate from 'vue-awesome-paginate';
import '@fortawesome/fontawesome-free/css/all.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vuetify/styles';
import 'vue3-toastify/dist/index.css';
import './plugins/font-awesome-icons';
import './styles/app.css';
import 'animate.css';
import vuetify from './plugins/vuetifyPlugin';

const vueToastifyOptions = {
  autoClose: 3000,
  rtl: true,
};

createApp(App)
  .use(router)
  .use(i18n)
  .use(store)
  .use(vuetify)
  .use(autoAnimatePlugin)
  .use(VueAwesomePaginate)
  .use(VueApexCharts)
  .use(Vue3Toastify, vueToastifyOptions as ToastContainerOptions)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app');
