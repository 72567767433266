import { ActionTree } from 'vuex';
import LoadingStateInterface from '@/store/modules/loading/loading-state.interface';

export const actions: ActionTree<LoadingStateInterface, unknown> = {
  finishWithError(state, payload) {
    state.commit('error');
    state.commit('setMessage', payload);
    state.commit('finishLoading');
  },
  finishSuccessfully(state, payload) {
    state.commit('success');
    state.commit('setMessage', payload);
    state.commit('finishLoading');
  },
};
