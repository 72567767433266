import useTokenStatus from '@/api/useTokenStatus';
import { AuthStoreInterface } from '@/types/auth/auth-interface';
import {setLocalStorge, getLocalStorge, removeLocalStorge, removeALl} from '@/helpers/localStorage';
import {getSessionStorge, setSessionStorge, removeSessionStorge, removeAllSession} from '@/helpers/sessionStorage';
import { AuthenticationResponse } from '@/types/auth/auth-interface';
import { isTokenDateValid } from '@/api/useTokenStatus';
import { StoreModulesEnum } from '@/types/store/modules.enum';
import store from '@/store';

const useAuthStatus = () => {
  const isMerchantAuthenticated = store.getters[`${StoreModulesEnum.Authentication}/isMerchantAuthenticated`];
  const { merchantToken, merchantTokenValid } = useTokenStatus();

  const merchantAuthenticated = isMerchantAuthenticated.value && !!merchantToken && merchantTokenValid;

  return {
    merchantAuthenticated,
  };
};

export default useAuthStatus;

export const saveAuthStateToLocalStorageAndGlobalState = (response: any, rememberMerchant = false) => {
  if (rememberMerchant) {
    // local storage
    setLocalStorge(AuthenticationResponse.MerchantToken, response.data.token.access);
    setLocalStorge(AuthenticationResponse.TokenType, response.data.token.type);
    setLocalStorge(AuthenticationResponse.TokenExpiresAt, response.data.token.expires_at);
    setLocalStorge(AuthenticationResponse.Platform, response.data.store.platform);
    setLocalStorge(AuthenticationResponse.StoreID, response.data.store.store_id);
    setLocalStorge(AuthenticationResponse.Mobile, response.data.store.mobile);
    setLocalStorge(AuthenticationResponse.Email, response.data.store.email);
    setLocalStorge(AuthenticationResponse.StoreURL, response.data.store.store_url);
    setLocalStorge(AuthenticationResponse.StoreTitle, response.data.store.title);
  } else {
    // session storage
    setSessionStorge(AuthenticationResponse.MerchantToken, response.data.token.access);
    setSessionStorge(AuthenticationResponse.TokenType, response.data.token.type);
    setSessionStorge(AuthenticationResponse.TokenExpiresAt, response.data.token.expires_at);
    setSessionStorge(AuthenticationResponse.Platform, response.data.store.platform);
    setSessionStorge(AuthenticationResponse.StoreID, response.data.store.store_id);
    setSessionStorge(AuthenticationResponse.Mobile, response.data.store.mobile);
    setSessionStorge(AuthenticationResponse.Email, response.data.store.email);
    setSessionStorge(AuthenticationResponse.StoreURL, response.data.store.store_url);
    setSessionStorge(AuthenticationResponse.StoreTitle, response.data.store.title);
    setLocalStorge('session-storage', JSON.stringify(sessionStorage));
  }
  // global store
  store.dispatch(`${StoreModulesEnum.Authentication}/setXMerchantToken`, response.data.token.access);
  store.dispatch(`${StoreModulesEnum.Authentication}/setMerchantTokenType`, response.data.token.type);
  store.dispatch(`${StoreModulesEnum.Authentication}/setTokenExpirationDate`, response.data.token.expires_at);
  store.dispatch(`${StoreModulesEnum.Authentication}/setMerchantStore`, response.data.store);
  store.dispatch(`${StoreModulesEnum.Authentication}/setMerchantAuthenticated`, true);
};

export const resetStorageAndGlobalStateAuth = () => {
  // local
  removeALl();
  // session
  removeAllSession()
  // global store
  store.dispatch(`${StoreModulesEnum.Authentication}/resetMerchantData`);
};

const getValuesFromStorage = () => {
  let xMerchantToken = null,
    tokenType = null,
    tokenExpiryDate = null,
    merchantStore = null;

  if (getLocalStorge(AuthenticationResponse.TokenExpiresAt) !== null) {
    xMerchantToken = getLocalStorge(AuthenticationResponse.MerchantToken);
    tokenType = getLocalStorge(AuthenticationResponse.TokenType);
    tokenExpiryDate = getLocalStorge(AuthenticationResponse.TokenExpiresAt);

    merchantStore = {
      platform: getLocalStorge(AuthenticationResponse.Platform),
      store_id: getLocalStorge(AuthenticationResponse.StoreID),
      mobile: getLocalStorge(AuthenticationResponse.Mobile),
      email: getLocalStorge(AuthenticationResponse.Email),
      store_url: getLocalStorge(AuthenticationResponse.StoreURL),
      title: getLocalStorge(AuthenticationResponse.StoreTitle),
    };
  } else {
    const sessionStorageData = getLocalStorge('session-storage');
    if (sessionStorageData !== null) {
      const data = JSON.parse(sessionStorageData);
      for (const key in data) {
        setSessionStorge(key, data[key]);
      }
    }
    xMerchantToken = getSessionStorge(AuthenticationResponse.MerchantToken);
    tokenType = getSessionStorge(AuthenticationResponse.TokenType);
    tokenExpiryDate = getSessionStorge(AuthenticationResponse.TokenExpiresAt);

    merchantStore = {
      platform: getSessionStorge(AuthenticationResponse.Platform),
      store_id: getSessionStorge(AuthenticationResponse.StoreID),
      mobile: getSessionStorge(AuthenticationResponse.Mobile),
      email: getSessionStorge(AuthenticationResponse.Email),
      store_url: getSessionStorge(AuthenticationResponse.StoreURL),
      title: getSessionStorge(AuthenticationResponse.StoreTitle),
    };
  }

  return {
    xMerchantToken,
    tokenType,
    tokenExpiryDate,
    merchantStore,
  };
};

export const isMerchantAuthenticatedLocally = () => {
  const { xMerchantToken, tokenType, tokenExpiryDate, merchantStore } = getValuesFromStorage();
  if (xMerchantToken && tokenType && tokenExpiryDate && merchantStore) {
    return isTokenDateValid(tokenExpiryDate);
  }
  return false;
};

export const updateGlobalStorageWithLocal = () => {
  const { xMerchantToken, tokenType, tokenExpiryDate, merchantStore } = getValuesFromStorage();

  store.dispatch(`${StoreModulesEnum.Authentication}/setXMerchantToken`, xMerchantToken);
  store.dispatch(`${StoreModulesEnum.Authentication}/setMerchantTokenType`, tokenType);
  store.dispatch(`${StoreModulesEnum.Authentication}/setTokenExpirationDate`, tokenExpiryDate);
  store.dispatch(`${StoreModulesEnum.Authentication}/setMerchantStore`, merchantStore);
  store.dispatch(`${StoreModulesEnum.Authentication}/setMerchantAuthenticated`, true);
};
