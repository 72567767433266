import httpClient from '../../../api/httpClient';
import ExternalSupportModel from '@/domains/shared/models/ExternalSupportModel';
import SupportResponseModel from '@/domains/shared/models/SupportResponseModel';
import InternalSupportModel from '@/domains/shared/models/InternalSupportModel';

const EXTERNAL_ENDPOINT_URL = '/merchant/external-support';
const INTERNAL_ENDPOINT_URL = '/merchant/support';

export const internalSupport = (supportInformation: InternalSupportModel) => {
  return httpClient
    .post(INTERNAL_ENDPOINT_URL, supportInformation)
    .then((response) => new SupportResponseModel(response))
    .catch((error) => error);
};

export const externalSupport = (supportInformation: ExternalSupportModel) => {
  return httpClient
    .post(EXTERNAL_ENDPOINT_URL, supportInformation)
    .then((response) => new SupportResponseModel(response))
    .catch((error) => error);
};
