import { MutationTree } from 'vuex';
import { CampaignsStateInterface } from './campaigns-state.interface';

export const mutations: MutationTree<CampaignsStateInterface> = {
  SET_CAMPAIGNS(state, payload) {
    state.campaigns = payload;
  },
  SET_CREATE_CAMPAIGN(state, payload) {
    state.createCampaign = { ...state.createCampaign, ...payload };
  },
  SET_CREATE_CAMPAIGN_ERRORS(state, payload) {
    state.createCampaignErrors = { ...state.createCampaignErrors, ...payload };
  },
  SET_PRODUCTS(state, payload) {
    state.products = { ...state.products, ...payload };
  },
  CLEAR_CREATE_CAMPAIGN(state, payload) {
    state.createCampaign = payload;
  },
};
