import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { useAuthentication } from './authentication/useAuthentication';

export const useGlobalGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  useAuthentication(to, from, next);
};
