/* eslint-disable */
import { RouteRecordRaw } from 'vue-router';
import { RoutesNamesEnum } from './routes-names.enum';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: RoutesNamesEnum.LogIn,
    redirect: '/login',
    component: () => import('@/components/auth-layout/AuthLayout.vue'),
    meta: { requireAuth: false },
    children: [
      {
        path: '/login',
        name: RoutesNamesEnum.LogIn,
        component: () => import('@/views/auth/LoginPage.vue'),
      },
      {
        path: '/contact-us',
        name: RoutesNamesEnum.ContactUs,
        component: () => import('@/views/auth/ContactUsPage.vue'),
        meta: { requireAuth: false },
      },
      {
        path: '/forgot-password',
        name: RoutesNamesEnum.ForgotPassword,
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: { requireAuth: false },
      },
      {
        path: '/reset-password',
        name: RoutesNamesEnum.ResetPassword,
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: '/sign-up',
        name: RoutesNamesEnum.SignUp,
        component: () => import('@/views/auth/SignupPage.vue'),
      },
      {
        path: '/logout',
        name: RoutesNamesEnum.LogOut,
        component: () => { },
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/components/app-container/AppContainer.vue'),
    meta: { requireAuth: true },
    children: [
      {
        path: '/dashboard',
        name: RoutesNamesEnum.Dashboard,
        component: () => import('@/views/DashboardPage.vue'),
      },
      {
        path: '/campaigns',
        name: RoutesNamesEnum.CurrentCampaigns,
        component: () => import('@/views/campaigns/CurrentCampaigns.vue'),
      },
      {
        path: 'campaigns/create',
        name: RoutesNamesEnum.CreateCampaign,
        component: () => import('@/views/campaigns/CreateCampaign.vue'),
      },
      {
        path: 'profile',
        name: RoutesNamesEnum.Profile,
        component: () => import('@/views/ProfilePage.vue'),
      },
      {
        path: 'analytics',
        name: RoutesNamesEnum.Analytics,
        component: () => import('@/views/AnalyticsPage.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: RoutesNamesEnum.LogIn },
  },
];

const addRequireAuthMeta = (route: any) => {
  route.meta = {
    ...route.meta,
    requireAuth: true,
  };
  if (route.children) {
    route.children.forEach((childRoute: any) => {
      addRequireAuthMeta(childRoute);
    });
  }
};

addRequireAuthMeta(routes);

export default routes;
