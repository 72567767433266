import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["dir"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SupportDialog = _resolveComponent("SupportDialog")!
  const _component_LoadingDialog = _resolveComponent("LoadingDialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bg-white md:bg-body-gradient rtl font-ReadexPro",
    dir: _ctx.$i18n.locale === _ctx.I18nLocalesEnum.EN ? _ctx.DirectionsEnum.LTR : _ctx.DirectionsEnum.RTL
  }, [
    _createVNode(_component_router_view),
    _createVNode(_component_SupportDialog),
    _createVNode(_component_LoadingDialog)
  ], 8, _hoisted_1))
}