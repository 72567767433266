interface SupportModelArg {
  message: string;
}

class InternalSupportModel {
  public message: string;

  constructor(arg: SupportModelArg) {
    this.message = arg.message;
  }
}

export default InternalSupportModel;
