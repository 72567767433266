/* eslint-disable */
import { ActionTree } from 'vuex';
import { CampaignsStateInterface } from './campaigns-state.interface';
import { CampaignsStateEnum } from './campaigns-state.interface';
import {getInitialCreateCampaignState} from "@/store/modules/campaigns/index";

export const actions: ActionTree<CampaignsStateInterface, any> = {
    setAllCampaigns({ commit }) {
        commit(CampaignsStateEnum.SetAllCampaigns);
    },
    setCreateCampaign({ commit }, info) {
        commit(CampaignsStateEnum.setCreateCampaign, info);
    },
    setCreateCampaignErrors({ commit }, info) {
        commit(CampaignsStateEnum.setCreateCampaignErrors, info);
    },
    setProducts({ commit }, info) {
        commit(CampaignsStateEnum.setProducts, info);
    },
    clearCreateCampaign({commit}) {
        commit(CampaignsStateEnum.clearCreateCampaign, getInitialCreateCampaignState());
    }
};

