/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';
import { setLocalStorge, getLocalStorge } from '@/helpers/localStorage';
import { LocaleStorage, I18nLocalesEnum } from '@/types/i18n/i18n.enum';

function loadLocaleMessages(): { [x: string]: LocaleMessages<VueMessageType> } {
  // @ts-ignore
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: { [x: string]: LocaleMessages<VueMessageType> } = {};
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export const getSelectedLocaleFromLocalStorage = () => {
  const storedLocale = getLocalStorge(LocaleStorage.ZiadahLocale);

  if (!storedLocale) {
    setLocalStorge(LocaleStorage.ZiadahLocale, I18nLocalesEnum.AR);
    return I18nLocalesEnum.AR;
  }

  switch (storedLocale) {
    case I18nLocalesEnum.AR:
      return I18nLocalesEnum.AR;
    case I18nLocalesEnum.EN:
      return I18nLocalesEnum.EN;
    default:
      return I18nLocalesEnum.AR;
  }
};

export default createI18n({
  legacy: false,
  locale: getSelectedLocaleFromLocalStorage(),
  // @ts-ignore
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  globalInjection: true,
  messages: loadLocaleMessages(),
});
