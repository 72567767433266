export default {
  "common": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جارٍ التحميل"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "EnterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الاسم"])},
    "EnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل البريد الالكتروني"])},
    "EnterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم الهاتف"])},
    "EnterStoreUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رابط المتجر"])},
    "TypeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رسالتك"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
    "EmailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني غير صحيح"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "PasswordNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور غير صحيحة"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة السر"])},
    "readyExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمثلة جاهزة!"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار"])},
    "i18n": {
      "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانجليزية"])},
      "Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
      "singleEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانجليزية"])},
      "singleArabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])}
    },
    "contactUsToGetMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا للحصول على ظهور أكثر"])},
    "upgradeNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالترقية الآن"])},
    "viewReachedLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الوصول لحد الظهور الأعلى"])},
    "viewAboutReachedLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الظهور على وشك الوصول للحد الأعلى"])},
    "subscriptionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهت مدة الاشتراك الخاص بك"])},
    "unlockSubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاتعطل نمو المبيعات وفعل الاشتراك الآن"])},
    "totalGrossSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المبيعات"])},
    "viewToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مرات ظهور الحملات في المتجر للعملاء."])},
    "clickToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المنتجات التي تمت إضافتها للسلة عن طريق الحملات بشكل مباشر."])},
    "conversionsToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع المنتجات التي تم شراؤها طريق الحملات."])},
    "tooltipGrossSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع قيمة المنتجات التي تم شرائها من الحملات <b>قبل تطبيق أي خصم.</b>"])},
    "campaignByView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات حسب الظهور"])},
    "campaignByConversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات حسب التحويل"])},
    "campaignByGrossSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات حسب إجمالي المبيعات"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الحملة"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الظهور"])},
    "requiredFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقل إجباري!"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدث"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشغل"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوى"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة النشر"])},
    "noCampaignFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاتوجد حملة مطابقة"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسبوع الماضي"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر الماضي"])},
    "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الماضي"])},
    "specificDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ محدد"])},
    "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
    "doesntShowCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيقاف ظهور الحملة"])},
    "showAlternativeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض منتجات بديلة"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقرات"])},
    "conversions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات"])},
    "gross_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المبيعات"])},
    "yAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y-محور"])},
    "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالترقية"])},
    "unlockNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استكشف البيانات الآن"])},
    "subtitleUnlockNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الخاصية متاحة لباقة النمو، والاحترافية، والأعمال."])},
    "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات التسويقية"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم"])}
  },
  "errors": {
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل البريد الإلكتروني الصحيح"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الصحيحة"])},
    "invalidEmailCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز المدخل غير صحيح"])}
  },
  "support": {
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])}
  },
  "components": {
    "header": {
      "DearMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عزيزي التاجر"])}
    },
    "sidebar": {
      "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحليلات"])},
      "Analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
      "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
      "Campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات التسويقية"])},
      "CurrentCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات الحالية"])},
      "NewCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حملة جديدة"])},
      "Soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قريباً"])},
      "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])}
    },
    "container": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])}
    },
    "productSelector": {
      "sku": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث برمز المنتج"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: Z.130216.1"])}
      },
      "category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف المنتج"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل "])}
      },
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتائج"])},
      "selectedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات المختارة"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار جميع المنتجات"])},
      "selectProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار"])},
      "selectedProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الاختيار"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخيارات"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
      "removeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المنتج"])},
      "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الكل"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
      "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
      "at_least_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب اختيار خيار واحد على الأقل"])},
      "noSelectedProductFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على المنتج"])},
      "noProductFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على المنتجات"])},
      "noProductFoundInCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على أي منتجات في التصنيف المحدد"])},
      "noCategoryFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على التصنيف"])},
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب اختيار قيمة واحدة على الأقل من كل خيار"])}
    },
    "datePicker": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار"])}
    },
    "ChangePassword": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
      "CurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الحالية"])},
      "NewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الجديدة"])},
      "NewPasswordHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* يجب أن تتكون كلمة المرور من 8 أحرف على الأقل"])},
      "ReEnterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد ادخال كلمة المرور الجديدة"])},
      "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
      "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])}
    },
    "ChangeEmail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير البريد الإلكتروني"])},
      "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل البريد الإلكتروني الجديد"])},
      "reNewEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد ادخال البريد الإلكتروني الجديد"])}
    },
    "viewCampaign": {
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استبدال"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة للسلة"])},
      "replaceAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استبدال الكل"])},
      "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف الكل"])},
      "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكمل التسوق"])},
      "expiresOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنتهي القسيمة في"])},
      "freeCOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع عند الاستلام مجاني"])},
      "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن مجاني"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخيارات"])},
      "afterAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: 2030/01/01"])},
      "enterUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط صفحة الخلفية"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاشة العرض"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغة العرض"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط تشغيل الخلفية"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])}
    }
  },
  "pages": {
    "login": {
      "boostSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع معدل المبيعات ورضى العملاء معاً"])},
      "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "RememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذكرني"])},
      "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب جديد"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم"])}
    },
    "forgotPassword": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])}
    },
    "resetPassword": {
      "code": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الرمز المرسل إلى بريدك الإلكتروني"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الرمز"])}
      },
      "password": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الجديدة"])},
        "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* يجب أن تتكون كلمة المرور من 8 أحرف على الأقل"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "password_confirmation": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد ادخال كلمة المرور"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين كلمة المرور"])},
      "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين كلمة المرور"])}
    },
    "changePassword": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
      "OldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الحالية"])},
      "NewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الجديدة"])},
      "ConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتأكيد كلمة المرور"])},
      "PasswordNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور غير صحيحة"])},
      "ConfirmPasswordNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور ليست متطابقة"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])}
    },
    "signUp": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعّل التطبيق الآن"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التفعيل من خلال منصة زد مباشرة"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ التفعيل من هنا"])}
    },
    "currentCampaigns": {
      "titleArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات المحذوفة"])},
      "subTitleArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعرض بيانات الحملات المحذوفة و استعيدها بكل سهولة"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات الحالية"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإداراة واستعراض جميع الحملات"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حملة جديدة"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث باسم الحملة"])},
      "perPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد النتائج"])},
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة المحذوفات"])},
      "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآداء"])},
      "buttonArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
      "tooltipArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملة مفعلة الآن, إذا قمت بحذفها سيتم إلغاء تفعيلها ولن تظهر في المتجر "])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الحملة"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
      "arrangePriority": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب الأولوية"])},
        "subtitlePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد أولوية الحملات عن طريق سحبها للأعلى أو للأسفل. سيتم عرض الحملة الموجودة أعلى القائمة عندما يقوم العميل باستهداف نفس المنتج في أكثر من حملة وبنفس الحدث."])},
        "subtitlePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(المنتج والحدث),"])},
        "subtitlePart3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حيث سيتم عرض الحملة الموجودة أعلى القائمة."])},
        "chooseEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الحدث"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "noCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد حملات"])}
      },
      "campaignFilters": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلترة الحملات"])}
      },
      "table": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة النشر"])},
        "publishDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النشر"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوى"])},
        "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشكل"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشغّل"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدث"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
        "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأولوية"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوى"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدء"])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])}
      },
      "currentCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات الحالية"])},
      "triggerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المنتجات"])},
      "mainProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات الأساسية"])},
      "alternativeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات البديلة"])},
      "limitedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى لكمية المنتجات"])},
      "stockLimitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حد المخزون"])},
      "avoidConflictOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجنب تعارض المنتجات"])},
      "noCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاتظهر الحملة"])},
      "campaignTrigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات المشغلة"])},
      "campaignAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الحملة"])},
      "campaignActionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر البيانات التالية في الحملة على متجرك"])},
      "selectedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستعمل المنتجات التالية على تشغيل الحملة عندما يتم العميل الحدث المحدد"])},
      "options": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملة مفعلة"])},
        "notAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لن تظهر الحملة في المتجر"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملة غير مفعلة"])},
        "someAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لن يتم عرض بعض المنتجات في الحملة"])}
      },
      "tooltip": {
        "notAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المنتجات المحددة للظهور في الحملة غير متوفرة في المخزون. ولذلك، لن تظهر الحملة في المتجر حتى يتم تحديث المخزون."])},
        "someAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعض المنتجات المحددة للظهور في الحملة غير متوفرة في المخزون. ولذلك، سيتم إخفائها من الحملة حتى يتم تحديث المخزون مرة أخرى."])}
      }
    },
    "newCampaign": {
      "max4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إضافة 4 منتجات أساسية فقط"])},
      "availableFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاحة لـ: :"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حملة جديدة"])},
      "GPEMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النمو، الاحترافية، الأعمال"])},
      "PEMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحترافية، و الأعمال"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالترقية"])},
      "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع مبيعاتك ومعدل رضى العملاء بالتسويق المباشر في متجرك عن طريق استهداف العملاء خلال رحلتهم الشرائية بإنشاء نوافذ لعرض منتجات مقترحة أو خصومات."])},
      "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* ملاحظة: إذا كان متجرك يدعم اللغة الإنجليزية والعربية، قم بالكتابة باللغتين."])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر الحملة"])},
      "viewCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الحملة"])},
      "name": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الحملة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيظهر هذا الاسم لك فقط هنا في لوحة التحكم، ولن يراه العملاء."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: حملة اليوم الوطني"])}
      },
      "event": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الحدث المستهدف"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متى تريد أن تظهر الحملة التسويقية للعملاء؟"])}
      },
      "type": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد نوع الحملة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماهو الهدف المطلوب تحقيقه من هذه الحملة؟"])}
      },
      "style": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد شكل الحملة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تريد أن تظهر الحملة للعملاء في المتجر"])}
      },
      "trigger": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد المنتج المشغّل للحملة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المنتج/المنتجات التي ستقوم بتشغيل هذه الحملة عندما يتم الحدث المحدد"])}
      },
      "action": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد محتوى الحملة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المحتوى الذي تريد أن يظهر في النافذة للعملاء"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات فقط"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر المنتجات التي ستختارها في النافذة."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات + قسيمة"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر المنتجات التي ستختارها مع قسيمة التخفيض المحددة في النافذة."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسيمة تخفيض فقط"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر قسيمة التخفيض في النافذة."])}
        }
      },
      "window": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان و وصف النافذة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب النص الذي سيظهر للعملاء في النافذة."])},
        "titleCard": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيظهر هذا النص في أعلى النافذة كعنوان أساسي."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب العنوان"])}
        },
        "descriptionCard": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيظهر هذا النص في أعلى النافذة كعنوان فرعي."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب الوصف"])}
        }
      },
      "couponCondition": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد شروط تطبيق القسيمة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر متى سيتمكن العميل من استخدام القسيمة"])},
        "allNonDiscountProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المنتجات ما عدا المنتجات المخفضة"])},
        "discountedProductOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات المخفضة فقط"])},
        "allProductExceptSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المنتجات ما عدا المنتجات المحددة"])},
        "selectedProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات محددة"])},
        "selectedCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات تصنيفات محددة"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيفات"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث"])},
        "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التصنيف"])}
      },
      "cardDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد النافذة"])},
        "subtitle": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بكتابة النص المطلوب الذي سيظهر في النافذة."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* ملاحظة: إذا كان متجرك يدعم اللغة الإنجليزية والعربية، قم بكتابة النصوص باللغتين."])}
        },
        "inputs": {
          "title": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيظهر هذا النص في أعلى النافذة كعنوان رئيس"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عنوان الحملة"])}
          },
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيظهر هذا النص في أعلى النافذة كعنوان فرعي"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الحملة"])}
          }
        }
      },
      "lifeTime": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد المدة الزمنية للحملة"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد متى تريد أن تظهر الحملة و متى ستتوقف."])},
        "startAt": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البداية"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد التاريخ المطلوب لبدء عرض الحملة للعملاء"])}
        },
        "endAt": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النهاية"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد التاريخ المطلوب لإيقاف عرض الحملة للعملاء"])}
        }
      },
      "couponTitle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان القسيمة"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيظهر هذا العنوان أعلى القسيمة"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب عنوان القسيمة"])}
      },
      "couponType": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الخصم"])},
        "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة من المجموع"])},
        "fixedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ من المجموع"])},
        "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن مجاني"])},
        "freeCOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدون رسوم الدفع عند الاستلام "])},
        "setDiscountValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الخصم"])},
        "setExpirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ نهاية القسيمة"])},
        "setMaximumAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للخصم"])},
        "setMinOrderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للطلب"])},
        "fixedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ محدد"])},
        "afterAppearBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد ظهور القسيمة بـ"])}
      },
      "productSelector": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المنتجات التي ستقوم بتشغيل هذه الحملة"])},
        "subtitle": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر المنتجات المنشورة في المتجر فقط."])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن تكون كمية المنتج أكبر من 0 حتى يظهر هنا"])},
          "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون المنتج في حملة سابقة بنفس الحدث والشكل"])}
        },
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])}
      },
      "mainProductSelector": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المنتجات التي سيتم عرضها في النافذة للعملاء"])},
        "subtitle": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر المنتجات المنشورة في المتجر فقط"])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن تكون كمية المنتج أكبر من 0 حتى يظهر هنا"])},
          "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون المنتج في الحملة السابقة بنفس الحدث والأسلوب"])},
          "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك اختيار 4 منتجات كحد أقصى"])}
        },
        "selectProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد المنتجات الرئيسة"])},
        "selectAlternativeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد المنتجات البديلة"])},
        "conflict": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجنب تعارض المنتجات"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عندما تكون المنتجات الرئيسة المحددة في الأعلى قد تمت إضافتها للسلة، ماذا تريد أن يحدث؟"])},
          "options": {
            "doNotShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تظهر هذه الحملة"])},
            "alternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعرض منتجات بديلة"])}
          }
        },
        "limitedQuantity": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى لكمية المنتجات"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت تريد تحديد حد أقصى لكمية المنتجات التي يمكن للعميل إضافتها من خلال النافذة، يمكنك تفعيل هذا الخيار."])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطيل"])}
        }
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "start_at": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "end_at": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي "])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفح معلومات حسابك والاشتراك الحالي"])},
      "accountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الحساب"])},
      "IntegratedPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة المتجر"])},
      "StoreInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المتجر"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المتجر"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المتجر"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط المتجر"])},
      "LoginInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الدخول للحساب"])},
      "Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك"])},
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعّال"])},
      "NonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير فعّال"])},
      "Upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترقية الباقة"])},
      "PlanDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الباقة"])},
      "PlanName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة"])},
      "ExpirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ نهاية الاشتراك"])},
      "ViewsWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الظهور"])},
      "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
      "Remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقي"])},
      "UpgradeToGetMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالترقية للحصول على عدد أكبر "])},
      "YouCanUpgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بترقية الباقة ودفع مبلغ الفرق فقط من الباقة الحالية, للحصول على عدد ظهور أكبر لحملاتك التسويقية"])}
    },
    "dashboard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحليلات"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلع على آداء وأثر حملاتك التسويقية المنشأة"])},
      "funnel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قمع الحملات التسويقية"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الظهور"])},
        "clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقرات"])},
        "conversions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات"])},
        "totalRevenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإيرادات الإجمالية"])}
      },
      "chart": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات عبر الزمن"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدث"])},
        "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النمط"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])}
      },
      "table": {
        "viewsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات حسب الظهور"])},
        "conversionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات حسب التحويل"])},
        "revenueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحملات حسب المبيعات"])},
        "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى"])},
        "lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأدنى"])}
      },
      "eventFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الأحداث"])}
      },
      "campaignFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الحملات"])}
      },
      "typeFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الأنواع"])}
      },
      "styleFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الأنماط"])}
      },
      "timeFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد التاريخ"])}
      }
    },
    "analytics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استكشف آداء كل حملة بتفاصيلها "])},
      "exportAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير الكل"])},
      "perPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد النتائج"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث حسب الحملة"])},
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب حسب"])},
      "filterCampagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الحملات"])},
      "table": {
        "header": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
          "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدث"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
          "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النمط"])},
          "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البداية"])},
          "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
          "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الظهور"])},
          "clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقرات"])},
          "conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويل"])},
          "revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإيرادات"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
          "gross_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المبيعات"])}
        },
        "filter": {
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعلة"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مفعلة"])}
        }
      }
    }
  }
}