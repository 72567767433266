import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export default {
  namespaced: true,
  state: {
    id: 0,
    storeId: 0,
    name: '',
    email: '',
    xMerchantToken: '',
    tokenExpirationDate: '',
    isMerchantAuthenticated: false,
    tokenType: '',
    store: {
      platform: '',
      store_id: 0,
      mobile: '',
      email: '',
      store_url: '',
      title: '',
    },
  },
  mutations,
  actions,
  getters,
};
