import { PeriodicModel } from '@/models/PeriodicModel';

export interface FilterInterface {
  id: number;
  name: {
    en: string;
    ar: string;
  };
}
export interface DashboardFilterState {
  event: FilterInterface[];
  type: FilterInterface[];
  campaign: FilterInterface[];
  date_type: any;
  date_from: any;
  date_to: any;
}

export interface DashboardStateInterface {
  filter: DashboardFilterState;
  total_views: number;
  total_click: number;
  total_conversion: number;
  total_gross: number;
  campaign_graph: PeriodicModel;
  filter_type_revenue: string;
  sortCampaignView: string;
  sortCampaignConversion: string;
  sortCampaignGrossSales: string;
  data_view: any[];
  data_conversation: any[];
  data_gross_sales: any[];
}

export enum DashboardStateEnum {
  setFilterByKey = 'SET_FILTER_BY_KEY',
  clearFilter = 'CLEAR_ALL_FILTER',
  clearFilterByKey = 'CLEAR_FILTER_BY_KEY',
  setTotalView = 'SET_TOTAL_VIEW',
  setTotalClick = 'SET_TOTAL_CLICK',
  setTotalConversion = 'SET_TOTAL_CONVERSION',
  setTotalGross = 'SET_TOTAL_GROSS',
  selectAllFilter = 'SELECT_ALL_FILTER',
  setFilterCustomDate = 'SET_FILTER_CUSTOM_DATE',
  setCampaignGraph = 'SET_CAMPAIGN_GRAPH',
  setFilterTypeRevenue = 'SET_FILTER_TYPE_REVENUE',
  setSortCampaignView = 'SET_SORT_CAMPAIGN_VIEW',
  setSortCampaignConversion = 'SET_SORT_CAMPAIGN_CONVERSION',
  setSortCampaignGrossSales = 'SET_SORT_CAMPAIGN_GROSS_SALES',
  setCampaignView = 'SET_CAMPAIGN_VIEW',
  setCampaignConversion = 'SET_CAMPAIGN_CONVERSION',
  setCampaignGrossSales = 'SET_CAMPAIGN_GROSS_SALES',
}
