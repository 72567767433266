type RootStateType = {
  isAppLoading: boolean;
  supportDialog: boolean;
};

export enum RootMutationsEnum {
  IsAppLoading = 'IS_APP_LOADING',
  openSupportDialog = 'OPEN_SUPPORT_DIALOG',
  setSupportDialog = 'SET_SUPPORT_DIALOG',
}

export default RootStateType;
