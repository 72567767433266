import { MutationTree } from 'vuex';
import {
  DashboardStateInterface,
  DashboardFilterState,
  FilterInterface,
} from '@/store/modules/dashboard/dashboard-state.interface';
import { PeriodicModel } from '@/models/PeriodicModel';

export const mutations: MutationTree<DashboardStateInterface> = {
  SET_FILTER_CUSTOM_DATE(state, { start, end }: { start: string; end: string }) {
    state.filter['date_type'] = 4;
    state.filter['date_from'] = start;
    state.filter['date_to'] = end;
  },
  SELECT_ALL_FILTER(state, { key, value }: { key: keyof DashboardFilterState; value: any }) {
    const currentFilter = state.filter[key];
    if (Array.isArray(currentFilter)) {
      state.filter[key] = [...value];
    } else {
      state.filter[key] = value;
    }
  },
  SET_FILTER_BY_KEY(state, { key, value }: { key: keyof DashboardFilterState; value: any }) {
    const currentFilter = state.filter[key];

    if (Array.isArray(currentFilter)) {
      const valueIndex = currentFilter.map((c: FilterInterface) => c.id).indexOf(value.id);

      if (valueIndex === -1) {
        // Value does not exist in the array, add it
        state.filter[key] = [...currentFilter, value];
      } else {
        // Value exists in the array, remove it
        state.filter[key] = currentFilter.filter((_, index) => index !== valueIndex);
      }
    } else {
      // Handle the case where the filter is not an array (like date_type, date_from, date_to)
      state.filter[key] = value;
    }
  },
  CLEAR_ALL_FILTER(state) {
    state.filter = {
      event: [],
      type: [],
      campaign: [],
      date_type: null,
      date_from: null,
      date_to: null,
    };
  },
  CLEAR_FILTER_BY_KEY(state, { key }: { key: keyof DashboardFilterState }) {
    if (Array.isArray(state.filter[key])) {
      state.filter[key] = [];
    } else {
      state.filter[key] = null;
    }
  },
  SET_TOTAL_VIEW(state, value: number) {
    state.total_views = value;
  },
  SET_TOTAL_CLICK(state, value: number) {
    state.total_click = value;
  },
  SET_TOTAL_CONVERSION(state, value: number) {
    state.total_conversion = value;
  },
  SET_TOTAL_GROSS(state, value: number) {
    state.total_gross = value;
  },
  SET_CAMPAIGN_GRAPH(state, value: PeriodicModel) {
    state.campaign_graph = value;
  },
  SET_FILTER_TYPE_REVENUE(state, value) {
    state.filter_type_revenue = value;
  },
  SET_SORT_CAMPAIGN_VIEW(state, value) {
    state.sortCampaignView = value;
  },
  SET_SORT_CAMPAIGN_CONVERSION(state, value) {
    state.sortCampaignConversion = value;
  },
  SET_SORT_CAMPAIGN_GROSS_SALES(state, value) {
    state.sortCampaignGrossSales = value;
  },
  SET_CAMPAIGN_VIEW(state, value) {
    state.data_view = value;
  },
  SET_CAMPAIGN_CONVERSION(state, value) {
    state.data_conversation = value;
  },
  SET_CAMPAIGN_GROSS_SALES(state, value) {
    state.data_gross_sales = value;
  },
};
