import { actions } from '@/store/modules/dashboard/actions';
import { mutations } from '@/store/modules/dashboard/mutations';
import { getters } from '@/store/modules/dashboard/getters';

export default {
  namespaced: true,
  state: {
    filter: {
      event: [],
      type: [],
      campaign: [],
      date_type: null,
      date_from: null,
      date_to: null,
    },
    total_gross: 0,
    total_views: 0,
    total_click: 0,
    total_conversion: 0,
    campaign_graph: null,
    filter_type_revenue: 'views',
    sortCampaignView: 'desc',
    sortCampaignConversion: 'desc',
    sortCampaignGrossSales: 'desc',
    data_view: [],
    data_conversation: [],
    data_gross_sales: [],
  },
  actions,
  mutations,
  getters,
};
