import { ActionTree } from 'vuex';
import {
  DashboardStateEnum,
  DashboardStateInterface,
  DashboardFilterState,
  FilterInterface,
} from '@/store/modules/dashboard/dashboard-state.interface';
import * as DashboardAxios from '@/utils/dashboards';
const buildFilter = (state: any) => {
  let filter = {};
  if (state.filter.event && state.filter.event.length > 0) {
    filter = {
      ...filter,
      event: state.filter.event.map((e: FilterInterface) => e.id).join(','),
    };
  }
  if (state.filter.type && state.filter.type.length > 0) {
    filter = {
      ...filter,
      type: state.filter.type.map((e: FilterInterface) => e.id).join(','),
    };
  }
  if (state.filter.campaign && state.filter.campaign.length > 0) {
    filter = {
      ...filter,
      campaign_ids: state.filter.campaign.map((e: FilterInterface) => e.id).join(','),
    };
  }

  return filter;
};
export const actions: ActionTree<DashboardStateInterface, any> = {
  changeSortTable({ commit, dispatch }, { key, value }) {
    switch (key) {
      case 'views':
        commit(DashboardStateEnum.setSortCampaignView, value);
        dispatch('loadTableViews');
        break;
      case 'conversions':
        commit(DashboardStateEnum.setSortCampaignConversion, value);
        dispatch('loadTableConversions');
        break;
      case 'gross-sales':
        commit(DashboardStateEnum.setSortCampaignGrossSales, value);
        dispatch('loadTableGrossSales');
        break;
    }
  },
  setFilterTypeRevenue({ commit, dispatch }, value) {
    commit(DashboardStateEnum.setFilterTypeRevenue, value);
    dispatch(`loadCampaignChart`);
  },
  setFilterCustomDate({ commit, dispatch }, { start, end }) {
    commit(DashboardStateEnum.setFilterCustomDate, { start, end });
    dispatch(`reloadAll`);
  },
  selectAllFilter({ commit, dispatch }, { key, value }: { key: keyof DashboardFilterState; value: any }) {
    commit(DashboardStateEnum.selectAllFilter, { key, value });
    dispatch(`reloadAll`);
  },
  setFilterByKey({ commit, dispatch }, { key, value }: { key: keyof DashboardFilterState; value: any }) {
    commit(DashboardStateEnum.setFilterByKey, { key, value });
    dispatch(`reloadAll`);
  },
  clearFilter({ commit, dispatch }) {
    commit(DashboardStateEnum.clearFilter);
    dispatch(`reloadAll`);
  },
  clearFilterByKey({ commit, dispatch }, { key }: { key: keyof DashboardFilterState }) {
    commit(DashboardStateEnum.clearFilterByKey, { key });
    dispatch(`reloadAll`);
  },
  async loadFunnelMetrics({ commit, state }) {
    let filter = buildFilter(state);
    if (state.filter.date_type) {
      filter = {
        ...filter,
        date_type: state.filter.date_type,
      };
    }
    if (state.filter.date_type && state.filter.date_type === 4) {
      filter = {
        ...filter,
        date_from: state.filter.date_from,
        date_to: state.filter.date_to,
      };
    }
    const funnel = await DashboardAxios.getFunnelMetrics(filter);
    if (funnel && funnel.data) {
      commit(DashboardStateEnum.setTotalClick, funnel.data.clicks.total);
      commit(DashboardStateEnum.setTotalView, funnel.data.views.total);
      commit(DashboardStateEnum.setTotalConversion, funnel.data.conversions.total);
      commit(DashboardStateEnum.setTotalGross, funnel.data.gross_sales.total);
    }
  },
  async loadCampaignChart({ commit, state }) {
    let filter = buildFilter(state);
    filter = {
      ...filter,
      filter_type: state.filter_type_revenue || 'views',
    };
    let filterType = 'all_time';
    if (state.filter.date_type) {
      switch (state.filter.date_type) {
        case 1:
          filterType = 'last_week';
          break;
        case 2:
          filterType = 'last_month';
          break;
        case 3:
          filterType = 'last_year';
          break;
      }

      if (state.filter.date_type === 4) {
        filter = {
          ...filter,
          from: state.filter.date_from,
          to: state.filter.date_to,
        };
      }
    }
    filter = {
      ...filter,
      filter: filterType,
    };
    const graph = await DashboardAxios.getCampaignChart(filter);
    commit(DashboardStateEnum.setCampaignGraph, graph);
  },
  async loadTableViews({ commit, state }) {
    let filter = buildFilter(state);
    if (state.filter.date_type) {
      filter = {
        ...filter,
        date_type: state.filter.date_type,
      };
    }
    if (state.filter.date_type && state.filter.date_type === 4) {
      filter = {
        ...filter,
        date_from: state.filter.date_from,
        date_to: state.filter.date_to,
      };
    }
    filter = {
      ...filter,
      sort: state.sortCampaignView,
    };
    const data = await DashboardAxios.getCampaignByView(filter);
    commit(DashboardStateEnum.setCampaignView, data);
  },
  async loadTableConversions({ commit, state }) {
    let filter = buildFilter(state);
    if (state.filter.date_type) {
      filter = {
        ...filter,
        date_type: state.filter.date_type,
      };
    }
    if (state.filter.date_type && state.filter.date_type === 4) {
      filter = {
        ...filter,
        date_from: state.filter.date_from,
        date_to: state.filter.date_to,
      };
    }
    filter = {
      ...filter,
      sort: state.sortCampaignConversion,
    };
    const data = await DashboardAxios.getCampaignByConversion(filter);
    commit(DashboardStateEnum.setCampaignConversion, data);
  },
  async loadTableGrossSales({ commit, state }) {
    let filter = buildFilter(state);
    if (state.filter.date_type) {
      filter = {
        ...filter,
        date_type: state.filter.date_type,
      };
    }
    if (state.filter.date_type && state.filter.date_type === 4) {
      filter = {
        ...filter,
        date_from: state.filter.date_from,
        date_to: state.filter.date_to,
      };
    }
    filter = {
      ...filter,
      sort: state.sortCampaignGrossSales,
    };
    const data = await DashboardAxios.getCampaignByGrossSales(filter);
    commit(DashboardStateEnum.setCampaignGrossSales, data);
  },
  reloadAll({ dispatch, commit }) {
    dispatch(`loadFunnelMetrics`);
    dispatch(`loadCampaignChart`);
    dispatch(`loadTableViews`);
    dispatch(`loadTableConversions`);
    dispatch(`loadTableGrossSales`);
  },
};
