import { GetterTree } from 'vuex';
import AuthenticationStateInterface from './authentication-state.interface';

export const getters: GetterTree<AuthenticationStateInterface, any> = {
  id: (state) => state.id,
  storeId: (state) => state.storeId,
  name: (state) => state.name,
  email: (state) => state.email,
  xMerchantToken: (state) => state.xMerchantToken,
  tokenExpirationDate: (state) => state.tokenExpirationDate,
  isMerchantAuthenticated: (state) => state.isMerchantAuthenticated,
  tokenType: (state) => state.tokenType,
  merchantStore: (state) => state.store,
};
