import dayjs from 'dayjs';
import { StoreModulesEnum } from '@/types/store/modules.enum';
import store from '@/store';

const useTokenStatus = () => {
  const tokenExpirationDate = store.getters[`${StoreModulesEnum.Authentication}/tokenExpirationDate`];
  const xMerchantToken = store.getters[`${StoreModulesEnum.Authentication}/xMerchantToken`];

  const merchantTokenValid = isTokenDateValid(tokenExpirationDate);
  const merchantToken = xMerchantToken;

  return {
    merchantToken,
    merchantTokenValid,
  };
};

export const isTokenDateValid = (tokenExpirationDate: string) => {
  return dayjs().isBefore(dayjs(tokenExpirationDate));
};

export default useTokenStatus;
