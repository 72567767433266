export enum LocaleStorage {
  ZiadahLocale = 'ziadah-locale',
}

export enum I18nLocalesEnum {
  EN = 'en',
  AR = 'ar',
}

export enum DirectionsEnum {
  LTR = 'ltr',
  RTL = 'rtl',
}
