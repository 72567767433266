import { MutationTree } from 'vuex';
import RootStateType from './RootStateType.type';

const mutations: MutationTree<RootStateType> = {
  IS_APP_LOADING(state, payload) {
    state.isAppLoading = payload;
  },
  OPEN_SUPPORT_DIALOG(state) {
    state.supportDialog = true;
  },
  SET_SUPPORT_DIALOG(state, payload) {
    state.supportDialog = payload;
  },
};

export default mutations;
