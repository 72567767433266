<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { RootMutationsEnum } from '@/store/RootStateType.type';
import { email, required, url } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import Times from '@/assets/icons/Times.vue';
import ExternalSupportModel from '@/domains/shared/models/ExternalSupportModel';
import InternalSupportModel from '@/domains/shared/models/InternalSupportModel';
import { internalSupport, externalSupport } from '@/domains/shared/api/support';
import ErrorModel from '@/api/models/ErrorModel';

const store = useStore();
const loading = ref(false);
const auth = computed(() => store.state.authentication.isMerchantAuthenticated);

const state = reactive({
  full_name: '',
  email: '',
  mobile: '',
  store_url: '',
  message: '',
});
const rules = computed(() => {
  let rules = { message: { required } };
  if (!auth.value) {
    rules = {
      ...rules,
      // @ts-ignore
      full_name: { required },
      email: { required, email },
      mobile: { required },
      store_url: { required, url },
    };
  }
  return rules;
});

const v$ = useVuelidate(rules, state);
const isOpen = computed({
  get() {
    return store.state.supportDialog;
  },
  set(newValue) {
    store.commit(RootMutationsEnum.setSupportDialog, newValue);
  },
});
const closeModal = () => {
  isOpen.value = false;
};
const reset = () => {
  v$.value.$reset();
  state.message = '';
  state.full_name = '';
  state.email = '';
  state.mobile = '';
  state.store_url = '';
}
const onSubmit = async () => {
  await v$.value.$validate();
  if (!v$.value.$invalid) {
    store.commit('loading/startLoading');
    let supportData, response;
    if (auth.value) {
      supportData = new InternalSupportModel(state);
      response = await internalSupport(supportData);
    } else {
      supportData = new ExternalSupportModel(state);
      response = await externalSupport(supportData);
    }
    if (response instanceof ErrorModel) {
      await store.dispatch('loading/finishWithError', response.errors);
      loading.value = false;

      return;
    }
    await store.dispatch('loading/finishSuccessfully', response.message);
    v$.value.$reset();
    reset();
    isOpen.value = false;
  }
};
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <div class="fixed inset-0 bg-black/30" aria-hidden="true" @click="closeModal" />
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <form @submit.prevent="onSubmit">
              <DialogPanel
                class="transform overflow-hidden rounded-[60px] bg-white rtl:text-right ltr:text-left align-middle shadow-xl transition-all p-[40px] w-[600px] flex flex-column justify-center align-center"
              >
                <DialogTitle class="d-block rtl:mr-auto ltr:ml-auto mb-[24px]">
                  <BaseIcon
                    height="12"
                    width="12"
                    view-box="0 0 25 25"
                    @click.prevent="closeModal"
                    class="cursor-pointer w-[22px] h-[22px]"
                  >
                    <Times />
                  </BaseIcon>
                </DialogTitle>
                <h1 class="ltr:font-Inter rtl:font-ReadexPro text-h1 leading-none font-700 text-purple mb-[21px]">
                  {{ $t('support.ContactUs') }}
                </h1>
                <template v-if="!auth">
                  <div class="d-flex mb-[15px]">
                    <div class="flex-1">
                      <label
                        class="ltr:font-Inter rtl:font-ReadexPro text-purple font-p4 mb-[10px] text-[16px]"
                        :class="v$.full_name.$errors.length ? 'text-red-900' : ''"
                        >{{ $t('common.EnterName') }}</label
                      >
                      <div class="rtl:pl-[70px] ltr:pr-[70px]">
                        <input
                          type="text"
                          class="ltr:font-Inter rtl:font-ReadexPro w-full p-[14px] rounded-xl border mt-1"
                          v-model="v$.full_name.$model"
                          :readonly="loading"
                          placeholder="ziadah"
                          @blur="v$.full_name.$touch"
                          :class="v$.full_name.$errors.length ? 'border-rose-500 placeholder-rose-500' : ''"
                        />
                      </div>
                    </div>
                    <div class="flex-1">
                      <label
                        class="ltr:font-Inter rtl:font-ReadexPro text-purple font-p4"
                        :class="v$.email.$errors.length ? 'text-red-900' : ''"
                        >{{ $t('common.EnterEmail') }}</label
                      >
                      <input
                        type="email"
                        class="ltr:font-Inter rtl:font-ReadexPro w-full p-[14px] rounded-xl border mt-1"
                        v-model="v$.email.$model"
                        :readonly="loading"
                        placeholder="ziadah@gmail.com"
                        @blur="v$.email.$touch"
                        :class="v$.email.$errors.length ? 'border-rose-500 placeholder-rose-500' : ''"
                      />
                    </div>
                  </div>
                  <div class="d-flex mb-[15px]">
                    <div class="flex-1">
                      <label
                        class="ltr:font-Inter rtl:font-ReadexPro text-purple font-p4 mb-[10px] text-[16px]"
                        :class="v$.mobile.$errors.length ? 'text-red-900' : ''"
                        >{{ $t('common.EnterMobile') }}</label
                      >
                      <div class="rtl:pl-[70px] ltr:pr-[70px]">
                        <input
                          type="text"
                          class="ltr:font-Inter rtl:font-ReadexPro w-full p-[14px] rounded-xl border mt-1"
                          v-model="v$.mobile.$model"
                          :readonly="loading"
                          placeholder="ziadah"
                          @blur="v$.mobile.$touch"
                          :class="v$.mobile.$errors.length ? 'border-rose-500 placeholder-rose-500' : ''"
                        />
                      </div>
                    </div>
                    <div class="flex-1">
                      <label
                        class="ltr:font-Inter rtl:font-ReadexPro text-purple font-p4"
                        :class="v$.store_url.$errors.length ? 'text-red-900' : ''"
                        >{{ $t('common.EnterStoreUrl') }}</label
                      >
                      <input
                        type="url"
                        class="ltr:font-Inter rtl:font-ReadexPro w-full p-[14px] rounded-xl border mt-1"
                        v-model="v$.store_url.$model"
                        :readonly="loading"
                        placeholder="https://example.com"
                        @blur="v$.store_url.$touch"
                        :class="v$.store_url.$errors.length ? 'border-rose-500 placeholder-rose-500' : ''"
                      />
                    </div>
                  </div>
                </template>
                <div class="w-full">
                  <label
                    class="ltr:font-Inter rtl:font-ReadexPro text-purple font-p4 mb-[10px]"
                    :class="v$.message.$errors.length ? 'text-red-900' : ''"
                    >{{ $t('common.TypeMessage') }}</label
                  >
                  <textarea
                    class="ltr:font-Inter rtl:font-ReadexPro p-[16px] rounded-xl border mt-1 h-[199px] w-full"
                    v-model="v$.message.$model"
                    :readonly="loading"
                    placeholder="ziadah@gmail.com"
                    @blur="v$.message.$touch"
                    :class="v$.message.$errors.length ? 'border-rose-500 placeholder-rose-500' : ''"
                    rows="7"
                  />
                </div>
                <button
                  class="bg-primary-gradient text-white w-full px-[40px] py-[15px] rounded-[12px] mb-[7px]"
                  type="submit"
                >
                  {{ $t('common.Send') }}
                </button>
              </DialogPanel>
            </form>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="scss"></style>
