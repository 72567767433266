interface SupportModelArg {
  full_name: string;
  email: string;
  // mobile: string;
  // store_url: string;
  message: string;
}

class ExternalSupportModel {
  public full_name: string;
  public email: string;
  // public mobile: string;
  // public store_url: string;
  public message: string;

  constructor(arg: SupportModelArg) {
    this.full_name = arg.full_name;
    this.email = arg.email;
    // this.mobile = arg.mobile;
    // this.store_url = arg.store_url;
    this.message = arg.message;
  }
}

export default ExternalSupportModel;
