import { MutationTree } from 'vuex';
import LoadingStateInterface from '@/store/modules/loading/loading-state.interface';

export const mutations: MutationTree<LoadingStateInterface> = {
  startLoading(state) {
    state.loading = true;
  },
  finishLoading(state) {
    state.loading = false;
  },
  error(state) {
    state.error = true;
  },
  success(state) {
    state.success = true;
  },
  setError(state, error) {
    state.error = error;
  },
  setSuccess(state, success) {
    state.success = success;
  },
  setMessage(state, message) {
    state.message = message;
  },
};
