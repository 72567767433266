import BaseModel from './BaseModel';

class ErrorModel extends BaseModel {
  public message: string;
  public errors: any;

  constructor(arg: any) {
    super(arg);
    this.status_code = arg.status;
    this.message = arg.data.message;
    this.errors = arg.data.errors;
  }
}

export default ErrorModel;
