import { MutationTree } from 'vuex';
import AuthenticationStateInterface from './authentication-state.interface';

export const mutations: MutationTree<AuthenticationStateInterface> = {
  RESET_MERCHANT_INFO(state) {
    state.id = 0;
    state.name = '';
    state.email = '';
    state.xMerchantToken = '';
    state.tokenExpirationDate = '';
    state.isMerchantAuthenticated = false;
    state.storeId = 0;
    state.tokenType = '';
    state.store = {
      mobile: 0,
      platform: '',
      store_id: 0,
      email: '',
      store_url: '',
      title: ''
    };
  },
  SET_MERCHANT_ID(state, payload) {
    state.id = payload;
  },
  SET_STORE_ID(state, payload) {
    state.storeId = payload;
  },
  SET_MERCHANT_NAME(state, payload) {
    state.name = payload;
  },
  SET_MERCHANT_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_X_MERCHANT_TOKEN(state, payload) {
    state.xMerchantToken = payload;
  },
  SET_TOKEN_EXPIRATION_DATE(state, payload) {
    state.tokenExpirationDate = payload;
  },
  SET_MERCHANT_AUTHENTICATED(state, payload) {
    state.isMerchantAuthenticated = payload;
  },
  SET_MERCHANT_TOKEN_TYPE(state, payload) {
    state.tokenType = payload;
  },
  SET_MERCHANT_STORE(state, payload) {
    state.store = payload;
  },
};
