export default class BaseModel {
  public status_code: number;
  public is_success: boolean;
  public message: string;
  constructor(arg: any) {
    this.status_code = arg.status_code;
    this.is_success = arg.is_success;
    this.message = arg.message;
  }
}
