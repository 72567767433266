import { createRouter, createWebHistory } from 'vue-router';
import { useGlobalGuard } from './guards/useGlobalGuard';
import routes from './routes';

const router = createRouter({
  // @ts-ignore
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  useGlobalGuard(to, from, next);
});

export default router;
