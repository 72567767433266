import { GetterTree } from 'vuex';
import { DashboardStateInterface } from '@/store/modules/dashboard/dashboard-state.interface';

export const getters: GetterTree<DashboardStateInterface, any> = {
  getAllFilter: (state) => state.filter,
  getFunnel: (state) => [state.total_views, state.total_click, state.total_conversion],
  getTotalGross: (state) => state.total_gross,
  getCampaignGraph: (state) => state.campaign_graph,
  getFilterTypeRevenue: (state) => state.filter_type_revenue,
  getDataTableView: (state) => state.data_view,
  getDataTableConversion: (state) => state.data_conversation,
  getDataTableGrossSales: (state) => state.data_gross_sales,
  getSortView: (state) => state.sortCampaignView,
  getSortConversion: (state) => state.sortCampaignConversion,
  getSortGrossSales: (state) => state.sortCampaignGrossSales,
};
