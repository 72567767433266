export default {
  "common": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "EnterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Name"])},
    "EnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Email"])},
    "EnterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Mobile"])},
    "EnterStoreUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Store Url"])},
    "TypeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your Message"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "EmailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail is not valid"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "PasswordNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is not valid"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget password"])},
    "readyExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready examples!"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "i18n": {
      "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
      "singleEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "singleArabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])}
    },
    "contactUsToGetMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us to get more"])},
    "upgradeNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade now"])},
    "viewReachedLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views reached limit!"])},
    "viewAboutReachedLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View's about to reach limit!"])},
    "subscriptionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription has expired"])},
    "unlockSubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock your growth and subscribe"])},
    "totalGrossSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Gross sales"])},
    "viewToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total number of campaigns that have appeared in the store"])},
    "clickToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total number of products that were directly added to from the campaign."])},
    "conversionsToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total number of ordered products that were directly added to from the campaign."])},
    "tooltipGrossSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total amount for products that were directly added from the campaign, <b>before any deductions.</b>"])},
    "campaignByView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign by views"])},
    "campaignByConversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign by Conversations"])},
    "campaignByGrossSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign by Gross sales"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
    "requiredFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in this!"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "noCampaignFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" No Campaign Found!!"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Week"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Month"])},
    "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Year"])},
    "specificDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Date"])},
    "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times"])},
    "doesntShowCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn’t show the campaign"])},
    "showAlternativeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display alternative products"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicks"])},
    "conversions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion"])},
    "gross_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Sales"])},
    "yAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y-Axis"])},
    "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
    "unlockNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock the numbers Now!"])},
    "subtitleUnlockNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is available only for: Growth, Pro, Enterprise plans"])},
    "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])}
  },
  "errors": {
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a correct email"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a correct password"])},
    "invalidEmailCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid code"])}
  },
  "support": {
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "components": {
    "header": {
      "DearMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Merchant"])}
    },
    "sidebar": {
      "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])},
      "Analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "Campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
      "CurrentCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Campaigns"])},
      "NewCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Campaigns"])},
      "Soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
      "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
    },
    "container": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log-out"])}
    },
    "productSelector": {
      "sku": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by product SKU"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex:Z.130216.1"])}
      },
      "category": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Category"])}
      },
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
      "selectedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Products"])},
      "selectedCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Categories"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
      "selectProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "selectedProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "removeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Product"])},
      "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove All"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard"])},
      "at_least_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one option"])},
      "noSelectedProductFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Selected Product Found"])},
      "noProductFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Products Found"])},
      "noCategoryFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Categories Found"])},
      "noProductFoundInCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products available in the selected category"])},
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one attribute from each option"])}
    },
    "datePicker": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])}
    },
    "ChangePassword": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "CurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the current password"])},
      "NewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the new password"])},
      "NewPasswordHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Password must be at least 8 characters"])},
      "ReEnterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter the new password"])},
      "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])}
    },
    "ChangeEmail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Email"])},
      "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the new email"])},
      "reNewEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter the new email"])}
    },
    "viewCampaign": {
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "replaceAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace All"])},
      "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add them all"])},
      "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Shopping"])},
      "expiresOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon expires on"])},
      "freeCOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free cash on delivery"])},
      "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free shipping"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "afterAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will Appear in the store"])},
      "enterUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter URL"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex:www.url.com"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    }
  },
  "pages": {
    "login": {
      "boostSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Boost Your Sales"])},
      "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "RememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
      "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget your password"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-up"])}
    },
    "forgotPassword": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
    },
    "resetPassword": {
      "code": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the sent code to your e-mail"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter reset code"])}
      },
      "password": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the new password"])},
        "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Password must-be at least 8 characters"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "password_confirmation": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter your password"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
      "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])}
    },
    "changePassword": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "OldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
      "NewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
      "ConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
      "PasswordNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is not valid"])},
      "ConfirmPasswordNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password does not match"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])}
    },
    "signUp": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate your store directly"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’re available on Zid now!"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Activation"])}
    },
    "currentCampaigns": {
      "titleArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived Campaigns"])},
      "subTitleArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage all your campaigns "])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Campaigns"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore and manage all your campaigns"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new campaign"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by campaign"])},
      "perPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result Per Page"])},
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
      "buttonArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "buttonUnArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive"])},
      "tooltipArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign is published now. If you archive it, it’ll be unpublished "])},
      "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "arrangePriority": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrange priority"])},
        "subtitlePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritize campaigns by dragging them up or down. The campaign at the top of the list will be shown when a customer triggers the same product in multiple campaigns with same event."])},
        "subtitlePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the same product"])},
        "subtitlePart3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in multiple campaigns with same event."])},
        "chooseEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose event"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "noCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no campaign"])}
      },
      "campaignFilters": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter campaigns"])}
      },
      "table": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "publishDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Date"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "currentCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Campaign"])},
      "mainProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Products"])},
      "alternativeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Products"])},
      "limitedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited Quantity"])},
      "stockLimitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock limitation"])},
      "triggerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Products"])},
      "campaignTrigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign trigger"])},
      "avoidConflictOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid conflict option"])},
      "noCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does’t show the campaign"])},
      "campaignAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Action"])},
      "campaignActionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The below products will run this campaign when triggered "])},
      "selectedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The below products will run this campaign when it's triggered"])},
      "options": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign is active and will appear in the store"])},
        "notAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign will not appear in the store"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign is not active"])},
        "someAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of the action products will not be shown in the campaign"])}
      },
      "tooltip": {
        "notAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the selected action products are out of stock. Therefore, the campaign will not appear in the store, until the stock is updated."])},
        "someAppear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of the selected action products are out of stock. Therefore, they will disappear from the campaign until the stock is updated again."])}
      }
    },
    "newCampaign": {
      "max4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only max of 4 products is allowed"])},
      "availableFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for :"])},
      "GPEMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth, Pro, Enterprise"])},
      "PEMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro and Enterprise"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a New Campaign"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
      "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost your sales by triggering your users depends on their behavior in your store"])},
      "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by products, bundles, and coupons."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Note that: If your store support English and Arabic language fill in the text in both."])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Campaign"])},
      "viewCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Campaign"])},
      "name": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name your campaign"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This name will appear to you only in the dashboard, and will not be seen by customers."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: National day campaign"])}
      },
      "event": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set event"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine when the campaign will start?"])}
      },
      "type": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set type"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose what is your goal from this campaign"])}
      },
      "style": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Style"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose how you want to appear this campaign in in the store"])}
      },
      "trigger": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set trigger"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the products that will run this campaign when the event occurs"])}
      },
      "action": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set action"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose what you want to present in the campaign"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Only"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User will see only products in the campaign."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products + Coupon"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User will see products and coupon in the campaign."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Only"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User will see only a coupon in the campaign."])}
        }
      },
      "window": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set window details"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the needed text to be appeared in the window to the user."])},
        "titleCard": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will appear in the top of the window as a main title."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write title"])}
        },
        "descriptionCard": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will appear in the top of the window as a sub-title."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write description"])}
        }
      },
      "couponCondition": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set coupon condition"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose when customer could use coupon"])},
        "allNonDiscountProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All none discounted products"])},
        "discountedProductOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounted products only"])},
        "allProductExceptSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products except selected products"])},
        "selectedProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected products"])},
        "selectedCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected categories"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Name"])}
      },
      "cardDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set card details"])},
        "subtitle": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the needed text to be appeared in your cart."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Note that: If your store support English and Arabic language fill in the text in both."])}
        },
        "inputs": {
          "title": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will appear in the top of the card as a main title."])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set coupon title"])}
          },
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will appear in the top of the as a sub-title ."])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set coupon description"])}
          }
        }
      },
      "lifeTime": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set life time campaign"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the date to appear and disappear your campaign."])},
        "startAt": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start at"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the needed date to start showing this campaign"])}
        },
        "endAt": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End at"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the needed date to stop showing this campaign"])}
        }
      },
      "couponTitle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set coupon title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will appear in the top of the coupon section."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write coupon title"])}
      },
      "couponType": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set coupon type"])},
        "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
        "fixedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Price"])},
        "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free shipping"])},
        "freeCOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free COD"])},
        "setDiscountValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount value"])},
        "setExpirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
        "setMaximumAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount"])},
        "setMinOrderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min order value"])},
        "fixedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed date"])},
        "afterAppearBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After appear by"])}
      },
      "productSelector": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the needed product to start this campaign"])},
        "subtitle": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product must be published to appear here"])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product must have quantity grater than 0 to appear here"])},
          "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product must not be in previous campaign with same event and style"])}
        },
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "mainProductSelector": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the products that will appear to the users in the campaign"])},
        "subtitle": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product must be published to appear here"])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product must have quantity grater than 0 to appear here"])},
          "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product mustn't be in previous campaign with same event and style"])},
          "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose maximum of 4 products"])}
        },
        "selectProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select main products"])},
        "selectAlternativeProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the alternative products"])},
        "conflict": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid product conflict"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the selected main products already in the cart what you’ll need to apply"])},
          "options": {
            "doNotShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't show the campaign"])},
            "alternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display alternative products"])}
          }
        },
        "limitedQuantity": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited quantity"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to limit customer to add specific quantity, enable this option."])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])}
        }
      }
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore all the detials about your profile and subscription"])},
      "accountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Details"])},
      "IntegratedPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated platform"])},
      "StoreInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store information"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "LoginInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login information"])},
      "Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "NonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Active"])},
      "Upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
      "PlanDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan Details"])},
      "PlanName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan Name"])},
      "ExpirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
      "ViewsWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views Details"])},
      "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "Remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
      "UpgradeToGetMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade To Get More"])},
      "YouCanUpgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upgrade your subscription to get more views in your campaign and pay only the difference amount"])}
    },
    "dashboard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure you have a comprehensive understanding of your campaign's performance"])},
      "funnel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns Funnel"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
        "clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicks"])},
        "conversions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion"])},
        "totalRevenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Revenue"])}
      },
      "chart": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns Revenue"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
        "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "table": {
        "viewsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns by views"])},
        "conversionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns by Conversions"])},
        "revenueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns by revenue"])},
        "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest"])},
        "lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest"])}
      },
      "eventFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])}
      },
      "campaignFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])}
      },
      "typeFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types"])}
      },
      "styleFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Styles"])}
      },
      "timeFilter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times"])}
      }
    },
    "analytics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain deep insights into your campaign's performance with a comprehensive reports"])},
      "exportAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export them all"])},
      "perPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result Per page"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by campaign"])},
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
      "filterCampagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter campaigns"])},
      "table": {
        "header": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
          "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
          "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
          "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
          "clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicks"])},
          "conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion"])},
          "revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "gross_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Sales"])}
        },
        "filter": {
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "nonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Active"])}
        }
      }
    }
  }
}