export enum RoutesNamesEnum {
  Dashboard = '/dashboard',
  Analytics = '/analytics',
  LogIn = '/login',
  ContactUs = '/contact-us',
  LogOut = '/logout',
  SignUp = '/signup',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  ChangePassword = '/change-password',
  CreateCampaign = '/campaigns/create',
  CurrentCampaigns = `/campaigns`,
  Profile = '/profile',
  NewCreateCampaign = '/new-create-campaign',
}
